import { mapStyles } from "./mapStyles";

export const defaultCenter = {
  lat: 38.65473178894704,
  lng: -90.5586679344962,
};

export const defaultZoom = 10;

export const mapOptions = {
  center: defaultCenter,
  clickableIcons: false,
  gestureHandling: "cooperative",
  maxZoom: 19,
  minZoom: 10,
  streetViewControl: false,
  styles: mapStyles,
  zoom: defaultZoom,
  zoomControl: true,
};

export const icons = {
  purple: {
    icon: "/img/map-pin-yellow.png",
  },
  green: {
    icon: "/img/map-pin-yellow.png",
  },
};

export const overviewMapOffset = {
  breakpoint: 1024,
  x: -150,
  y: 0,
};

export const mapWithInfoMapOffset = {
  breakpoint: 640,
  x: 200,
  y: 0,
};

export const mapArbitraryMapOffset = {
  breakpoint: 640,
  x: 0,
  y: 0,
};
