import gql from "graphql-tag";
import { TESTIMONIAL_FRAGMENT } from "./fragments.js";

export const GET_TESTIMONIALS = gql`
  ${TESTIMONIAL_FRAGMENT}
  query GetTestimonials(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    testimonials(
      first: $first
      after: $after
      before: $before
      last: $last
      where: {orderby: {field: DATE, order: DESC}}
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          ...TestimonialFragment
        }
      }
    }
  }
`;

export const GET_TESTIMONIAL_CATEGORIES = gql`
  query GetTestimonialCategories {
    testimonialCategories(where: {orderby: COUNT, order: DESC}) {
      nodes {
        count
        name
        slug
        uri
      }
    }
  }
`;

export const GET_POPULAR_TESTIMONIALS = gql`
  query GetPopularTestimonials {
    popularTestimonials
  }
`;

export const GET_TESTIMONIALS_BY_IDS = gql`
  ${TESTIMONIAL_FRAGMENT}
  query GetTestimonialsByIds($in: [ID]) {
    testimonials(where: {in: $in}) {
      edges {
        node {
          ...TestimonialFragment
        }
      }
    }
  }
`;


export const GET_TESTIMONIALS_BY_CATEGORY = gql`
  ${TESTIMONIAL_FRAGMENT}
  query GetTestimonialsByCategory($categorySlug: [String], $first: Int, $last: Int, $before: String, $after: String) {
    testimonialCategories(where: {slug: $categorySlug}) {
      edges {
        node {
          testimonials(where: {orderby: {field: DATE, order: DESC}}, first: $first, last: $last, before: $before, after: $after) {
            edges {
              node {
                ...TestimonialFragment
              }
            }
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_TESTIMONIAL_BY_SLUG = gql`
  ${TESTIMONIAL_FRAGMENT}
  query GetSingleTestimonialBySlug($id: ID!) {
    testimonial(id: $id, idType: SLUG) {
      ...TestimonialFragment
    }
  }
`;

