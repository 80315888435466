export const getInfowindowContent = (mapInfo) => {
  switch (mapInfo?.value?.mapView) {
    case "display-homes":
      return (
        mapInfo.value.nodes.map((node) => {
          const beds = (node.beds.min && node.beds.max) ? node.beds.min+' - '+node.beds.max : node.beds.min;
          const sqft = node?.sqft?.toLocaleString('en-US');
          return `
            <div class="infowindow-content infowindow-display-homes">
              <a href="${node.link}">
                <img src="${node.thumbnailSrc}" alt="${node.thumbnailAlt}">
              </a>
              <h2 class="title">${node.title}</h2>
              <div class="details">
                <div>
                  <span>${beds}</span>
                  <span>BEDS</span>
                </div>
                <div>
                  <span>${sqft}</span>
                  <span>SQ. FT.</span>
                </div>
              </div>
              <a class="directions-link" href="${node.directionsLink}" target="_blank">Get directions</a>
              <a class="explore-button" href="${node.link}">${node.linkText}</a>
            </div>
            `;
        }) ?? []
      );

    case "neighborhoods":
      return (
        mapInfo.value.nodes.map((node) => {
          return `
            <div class="infowindow-content infowindow-neighborhoods">
              <a href="${node.link}">
                <img src="${node.thumbnailSrc}" alt="${node.thumbnailAlt}">
              </a>
              <h2 class="title">${node.title}</h2>
              <p class="subtitle">${node.subtitle}</p>
              <a class="directions-link" href="${node.directionsLink}" target="_blank">Get directions</a>
              <a class="explore-button" href="${node.link}">${node.linkText}</a>
            </div>
            `;
        }) ?? []
      );

    case "quick-move-in":
      return (
        mapInfo.value.nodes.map((node) => {
          const beds = (node.beds.min && node.beds.max) ? node.beds.min+' - '+node.beds.max : node.beds.min;
          const baths = (node.baths.min && node.baths.max) ? node.baths.min+' - '+node.baths.max : node.baths.min;
          const sqft = node?.sqft?.toLocaleString('en-US');
          return `
            <div class="infowindow-content infowindow-qmi-homes">
              <a href="${node.link}">
                <img src="${node.thumbnailSrc}" alt="${node.thumbnailAlt}">
              </a>
              <h2 class="title">${node.title}</h2>
              <h3 class="plan">${node.plan}</h3>
              <div class="details">
                <div>
                  <span>${beds}</span>
                  <span>BEDS</span>
                </div>
                <div>
                  <span>${baths}</span>
                  <span>BATH</span>
                </div>
                <div>
                  <span>${sqft}</span>
                  <span>SQ. FT.</span>
                </div>
              </div>
              <a class="directions-link" href="${node.directionsLink}" target="_blank">Get directions</a>
              <a class="explore-button" href="${node.link}">${node.linkText}</a>
            </div>
            `;
        }) ?? []
      );
  }
};

